import axios from 'axios';

const axiosClient = axios.create({
    baseURL: 'https://alb88.live',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
    withCredentials: true,
});

axiosClient.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosClient.interceptors.response.use(
    (response) => {
        if (response && response.data) {
            return response;
        }

        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);
export default axiosClient;
