import { css } from '@emotion/css';
import { colors } from 'constants/theme';
import bgRegister from 'assets/images/bg-register.jpg';
import bgTitle from 'assets/images/title-register.jpg';
const registerStyle = {
    wrap: css`
        overflow: hidden;
        background: ${colors.bgDark};
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        @media (max-width: 950px) {
            align-items: flex-start;
        }
    `,
    pageContent: css`
        width: 100%;
        max-width: 1050px;
        margin: 0 auto;
        display: flex;
        border-radius: 16px;
        overflow: hidden;
        justify-content: center;
    `,
    left: css`
        flex: 0 0 55%;
        display: flex;
        flex-direction: column;
        img {
            width: 100%;
        }
        @media (max-width: 950px) {
            display: none;
        }
    `,
    logoPartner: css`
        display: flex;
        flex-wrap: wrap;

        background: ${colors.white};
        padding: 12px 10px;
        margin-top: 10px;
        flex: 1;
    `,
    imgReg: css`
        @media (max-width: 992px) {
            width: 63%;
        }
    `,
    partnerItem: css`
        flex: 0 0 20%;
        max-width: 20%;
        padding: 4px 8px;
    `,
    itemInner: css`
        border: 1px solid #e0e0e0;
        padding: 2px 8px;
        border-radius: 4px;
        img {
            width: 100%;
            height: 27px;
            object-fit: contain;
        }
    `,

    titlecontent: css`
        text-align: center;
        margin: 20px 0;
        img {
            width: 40%;
            max-width: 300px;
            min-width: 200px;
        }
    `,
    contentform: css`
        flex: 0 0 450px;
        max-width: 450px;
        background: url(${bgRegister}) no-repeat;
        padding: 30px 45px;
        @media (max-width: 950px) {
            flex: 1;
            max-width: 550px;
            background: none;
        }
        @media (max-width: 550px) {
            flex: 1;
            background: none;
            max-width: unset;
            padding: 40px 8px 0;
        }
    `,
    titleReg: css`
        border-radius: 10px;
        background: url(${bgTitle}) center center no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid ${colors.darkPrimary};
        padding: 8px 12px;
        margin-bottom: 24px;
        h6 {
            font-size: 22px;
            color: ${colors.white};
            font-weight: 500;
            margin-right: 20px;
        }
        img {
            width: 150px;
            cursor: pointer;
        }
    `,
    form: css`
        color: ${colors.white};
    `,
    inputWrap: css`
        position: relative;
        padding-bottom: 20px;
        label {
            font-size: 16px;
            display: block;
            margin-bottom: 6px;
        }
    `,
    input: css`
        background: rgba(0, 0, 0, 0.5);
        padding: 8px 12px;
        border: 1px solid ${colors.primary};
        border-radius: 8px;
        color: #fff;
        width: 100%;
        height: 100%;
        &::placeholder {
            font-size: 14px;
            color: #90949c;
            letter-spacing: 0;
        }
        &:focus {
            outline: none;
            border-color: ${colors.secondary};
        }
    `,

    errorInput: css`
        position: absolute;
        bottom: 4px;
        left: 2px;
        width: 100%;
        small {
            font-size: 12px;
            color: ${colors.red};
        }
    `,
    textTOF: css`
        font-size: 14px;
        font-weight: 300;
        a {
            color: ${colors.yellow};
            font-weight: 400;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    `,

    togglePass: css`
        position: absolute;
        cursor: pointer;
        right: 8px;
        top: 42px;
        transform: translateY(-50%);
        img {
            width: 18px;
        }
    `,
    btnSubmit: css`
        padding: 14px 0;
        font-weight: 500;
        border-radius: 8px;
        cursor: pointer;
        background: ${colors.secondary};
        color: ${colors.blackText};
        width: 100%;
        cursor: pointer;
        border: none;
        &:hover {
            background: #ffc107;
        }
        &:disabled {
            pointer-events: none;
            opacity: 0.7;
        }
    `,
    hasAccount: css`
        color: ${colors.white};
        text-align: center;
        margin-top: 8px;
        span {
            color: ${colors.secondary};
            font-style: italic;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    `,
};
export default registerStyle;
