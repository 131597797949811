import LoadingIcon from 'components/loading/LoadingIcon';
import { logoPartnerList } from 'constants/logoPartner';
import { msgAlertInput } from 'constants/messageAlert';
import { FormValuesRegister, RegisterPayload } from 'models/authModel';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import styles from './register.style';

type InputId = 'un' | 'pwd' | 'zjpwd' | 'nickname' | 'phone' | 'email';
interface InputItem {
    id: InputId;
    label: string;
    holder: string;
    inputType: string;
    require: boolean;
    togglePass?: boolean;
    pattern?: { value: RegExp; message: string };
    minLength?: { value: number; message: string };
    maxLength?: { value: number; message: string };
}
const listInput: InputItem[] = [
    {
        id: 'un',
        label: 'Tên Đăng Nhập',
        holder: msgAlertInput.username.invalid,
        inputType: 'text',
        require: true,
        pattern: {
            value: /^\S+$/,
            message: msgAlertInput.generic.invalid('Tên đăng nhập'),
        },

        maxLength: {
            value: 30,
            message: msgAlertInput.generic.maxLength(30),
        },
    },
    {
        id: 'nickname',
        label: 'Biệt Danh',
        holder: 'Nhập ký tự chữ và số',
        inputType: 'text',
        require: true,
        maxLength: {
            value: 30,
            message: msgAlertInput.generic.maxLength(30),
        },
    },
    {
        id: 'pwd',
        label: 'Mật Khẩu',
        holder: msgAlertInput.password.invalid,
        inputType: 'password',
        require: true,
        togglePass: true,
        maxLength: {
            value: 30,
            message: msgAlertInput.generic.maxLength(30),
        },
    },
    {
        id: 'zjpwd',
        label: 'Mật Khẩu Thanh Toán',
        holder: msgAlertInput.password.invalid,
        inputType: 'password',
        require: true,
        togglePass: true,
        maxLength: {
            value: 30,
            message: msgAlertInput.generic.maxLength(30),
        },
    },
    {
        id: 'phone',
        label: 'Số Điện Thoại',
        holder: 'Nhập vào số điện thoại',
        inputType: 'text',
        require: true,
        maxLength: {
            value: 12,
            message: msgAlertInput.generic.maxLength(12),
        },
    },

    // {
    //     id: 'email',
    //     label: 'Email (Không bắt buộc)',
    //     holder: 'Nhập đúng định dạng email',
    //     inputType: 'text',
    //     require: false,
    // },
];

interface Props {
    onRegister: (payload: RegisterPayload) => void;
    goToHome: () => void;
}

function Register(props: Props) {
    const { onRegister, goToHome } = props;

    const [showPass, setShowPass] = useState(false);
    const [showPassPay, setShowPassPay] = useState(false);
    const {
        handleSubmit,
        register,
        setFocus,
        setError,
        formState: { errors },
    } = useForm<FormValuesRegister>();

    const handleRegister: SubmitHandler<FormValuesRegister> = async (values) => {
        // const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        // if (values.email !== '' && !values.email.match(validRegex)) {
        //     toast.error('Vui lòng nhập đúng định dạng email');
        //     return;
        // }

        if (values.pwd === values.zjpwd) {
            setFocus('zjpwd');
            setError('zjpwd', {
                type: 'value',
                message: 'Mật khẩu thanh toán không được giống với đăng nhập',
            });
            return;
        }

        let fatherId = window.location.pathname.replace('/', '');
        if (!fatherId) {
            fatherId = !isMobile ? '1635310' : '1635320';
        }
        console.log(fatherId);
        const payload = {
            ...values,
            loginname: values.un.toLowerCase(),
            email: `${values.un.toLowerCase().trim()}_noreply@gmail.com`,
            agentid: '10020', // prod
            id: fatherId || '1635320', //rootmobi: 1635320, rootweb: 1635310
        };
        onRegister(payload);
    };

    return (
        <div className={styles.wrap}>
            <div className={styles.pageContent}>
                <div className={styles.left}>
                    <img src={require('assets/images/register.jpg')} alt="" />
                    <div className={styles.logoPartner}>
                        {logoPartnerList.map((item, index) => (
                            <div className={styles.partnerItem} key={index}>
                                <div className={styles.itemInner}>
                                    <img src={item} alt="" />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.contentform}>
                    <div className={styles.titleReg}>
                        <h6>ĐĂNG KÝ</h6>
                        <img src={require('assets/images/logo.png')} alt="" />
                    </div>
                    <form className={styles.form} onSubmit={handleSubmit(handleRegister)}>
                        {listInput.map((item, index) => (
                            <div className={styles.inputWrap} key={index}>
                                <label htmlFor={item.id}>{item.label}</label>
                                <input
                                    type={item.id === 'pwd' ? (!showPass ? item.inputType : 'text') : item.id === 'zjpwd' ? (!showPassPay ? item.inputType : 'text') : item.inputType}
                                    id={item.id}
                                    placeholder={item.holder}
                                    className={`input-common ${styles.input}`}
                                    {...register(item.id, {
                                        required: item.require,
                                        pattern: item.pattern,
                                        maxLength: item.maxLength,
                                        minLength: item.minLength,
                                    })}
                                    onChange={(e) => (e.target.value = e.target.value.toLowerCase())}
                                />
                                {/* toggle password login */}
                                {item.togglePass && item.id === 'pwd' && (
                                    <div className={styles.togglePass} onClick={() => setShowPass(!showPass)}>
                                        {showPass ? <img src={require('assets/images/icons/eye_on.png')} alt="" /> : <img src={require('assets/images/icons/eye_off.png')} alt="" />}
                                    </div>
                                )}

                                {/* toggle password pay */}
                                {item.togglePass && item.id === 'zjpwd' && (
                                    <div className={styles.togglePass} onClick={() => setShowPassPay(!showPassPay)}>
                                        {showPassPay ? <img src={require('assets/images/icons/eye_on.png')} alt="" /> : <img src={require('assets/images/icons/eye_off.png')} alt="" />}
                                    </div>
                                )}

                                {/* base error  */}
                                <div className={styles.errorInput}>{(errors as any)[item.id] && <small>{(errors as any)[item.id].message || 'Vui lòng nhập trường này!'}</small>}</div>
                            </div>
                        ))}

                        <button type="submit" className={styles.btnSubmit} style={{ marginTop: '16px' }}>
                            Đăng Ký
                        </button>
                        <p className={styles.hasAccount}>
                            Bạn đã có tài khoản! <span onClick={goToHome}>Đăng nhập ngay</span>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Register;
