import { AxiosResponse } from 'axios';
import { BaseResponse } from 'models/baseResponse';
import axiosClient from './aixosClient';

const authApi = {
    signUp(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'RegBean_regNew';
        return axiosClient.post(`RegisterService.aspx`, payload);
    },
    getDomainAvailable(payload: { flag?: string }): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'DomainBean_listDomain';
        return axiosClient.post(`UserService.aspx`, payload);
    },
};
export default authApi;
